.textAreaRow {
    margin-top: 20px;
    display: flex;
    width: 100%;
}

.textAreaRow > textarea {
    width: 100%;
    height: 300px;
    border: 1px solid #ccc;
    border-right: 0;
}

.textAreaRow > pre {
    width: 100%;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
}

.header {
    display: flex;
    align-items: center;
}

.header .title {
    font-size: 32px;
}

.header .button {
    padding: 10px;
    margin: 0;
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #ccc;
}

.header .button:hover {
    background-color: #ccc;
    cursor: pointer;
}
