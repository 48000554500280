.App {
  background-color: #ededed;
  margin: 0;
  padding: 10px;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.App-title {
  text-align: center;
}
